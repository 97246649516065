// src/components/Layout.js

import React, { useState } from 'react';
import MainContent from './maincontent';
import "../styles/global-layout.css"
import PrimarySearchAppBar from './navbar';
import TemporaryDrawer from './sidebar';

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <div className="layout">

      <TemporaryDrawer open={isSidebarOpen} />
      <div className={isSidebarOpen ? 'sidebar-open main-content' : 'sidebar-closed main-content'}
      >
        <PrimarySearchAppBar onSearch={handleSearch} onToggleSidebar={toggleSidebar} />

        <div className="content" >
          <MainContent searchQuery={searchQuery} />
        </div>
      </div>
    </div>
  );
};

export default Layout;