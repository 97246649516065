import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MoreIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import '../styles/navbar.css';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Tooltip } from '@mui/material';
import content from '../static_content';

/* const drawerWidth = 260; */
const role = 'Admin';
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#001D5B',
    zIndex: '1'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: '1px solid #F0F0F0',
        borderRadius: '4px',
        background: 'var(--Neutral-1, #FFF)',
        color: '#001D5B',
        justifyContent: 'flex-start',
        alignItems: 'center', gap: '10px', display: 'inline-flex',
        [theme.breakpoints.up('md')]: {
            width: '34ch',
        },
        [theme.breakpoints.up('lg')]: {
            width: '367px',
        },
    }


}));

const handleLogout = () => {
    console.log("User logged out");
    window.localStorage.removeItem('token');
    window.location.href = '/login';
};


export default function PrimarySearchAppBar({ onSearch, onToggleSidebar }) {
    const [isOpen, setIsOpen] = useState(true); // State to manage sidebar open/closed

    const toggleSidebar = () => {
        setIsOpen(!isOpen); // Toggle the sidebar open/close state
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [query, setQuery] = useState('');

    const handleInputChange = (e) => {
        const value = e.target.value;
        console.log('hit', value)
        setQuery(value);
        onSearch(value); // Pass the query back to the parent
    };
    // State for each menu
    const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
    const [settingsMenuAnchor, setSettingsMenuAnchor] = useState(null);
    const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
    const [feedbackMenuAnchor, setFeedbackMenuAnchor] = useState(null);

    // Handlers for opening each menu
    //const handleLanguageMenuOpen = (event) => setLanguageMenuAnchor(event.currentTarget);
    //const handleSettingsMenuOpen = (event) => setSettingsMenuAnchor(event.currentTarget);
    const handleProfileMenuOpen = (event) => setProfileMenuAnchor(event.currentTarget);
    //const handleFeedbackMenuOpen = (event) => setFeedbackMenuAnchor(event.currentTarget);

    // Handlers for closing each menu
    const handleMenuClose = () => {
        setLanguageMenuAnchor(null);
        setSettingsMenuAnchor(null);
        setProfileMenuAnchor(null);
        setFeedbackMenuAnchor(null);
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };


    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'language-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}

        >
            <MenuItem sx={{
                display: 'flex',
                padding: '12px',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                borderRadius: '4px',
                backgroundColor: '#F5F5F5'
            }}>
                <IconButton size="large" aria-label="show 4 new mails" color="secondary">
                    <Badge sx={{ color: '#001D5B' }}>
                        <EmailOutlinedIcon />
                    </Badge>
                </IconButton>
                <p>Feedback</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show languages"

                >
                    <Badge sx={{ color: '#001D5B' }}>
                        <LanguageOutlinedIcon />
                    </Badge>
                </IconButton>
                <p>Languages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show settings"

                >
                    <Badge sx={{ color: '#001D5B' }}>
                        <SettingsOutlinedIcon />
                    </Badge>
                </IconButton>
                <p>Settings</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color='#001D5B'
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu >
    );
    /*     const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
        useEffect(() => {
            const handleResize = () => {
                setWindowWidth(window.innerWidth);
            };
    
            window.addEventListener('resize', handleResize);
    
            // Clean up the event listener on component unmount
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []); */
    return (
        <Box sx={{
            flexGrow: 1
        }} >
            <AppBar position="relative" sx={{
                marginLeft: 'inherit',
                border: '1px solid #F0F0F0',
                background: '#FFF',
                boxShadow: '0px 0px 0px 1px rgba(24, 144, 255, 0.20)',
                zIndex: '1'
            }}>
                <Toolbar>
                    <IconButton
                        size="medium"
                        edge="start"
                        sx={{ color: '#001D5B', borderRadius: '4px' }}
                        aria-label="expand or collapse"
                        onClick={() => {
                            onToggleSidebar();
                            toggleSidebar();

                        }}
                    >
                        {isOpen ? <MenuOpenOutlinedIcon /> : <ChevronRightOutlinedIcon />}
                    </IconButton>


                    {/* 
                    <Autocomplete
                        freeSolo
                        sx={{
                            color: '#001D5B'
                        }}
                        id="search-page"
                        disableClearable
                        options={categories.map((option) => option.title)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search input"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}>
                        /></Autocomplete>

 */}
                    {/* Imolement autocomplete for search */}
                    <Search sx={{
                        color: '#001D5B'
                    }}>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            value={query} // Control the input value
                            onChange={handleInputChange} // Update the input change handler
                        />
                    </Search>
                    <Box sx={{ flexGrow: 1 }} />
                    {/* Feedback */}
                    <Box sx={{
                        display: {
                            xs: 'none', md: 'flex', lg: 'flex', gap: '10px'
                        }
                    }}>
                        <Tooltip title={content.future_scope_tooltip}
                            arrow>
                            <IconButton size="large" aria-label="show feedback" sx={{ borderRadius: '4px', backgroundColor: '#F5F5F5' }}
                            /*   onClick={handleFeedbackMenuOpen} */
                            >
                                <Badge sx={{ color: '#001D5B' }}>
                                    <EmailOutlinedIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={feedbackMenuAnchor}
                            open={Boolean(feedbackMenuAnchor)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleMenuClose}>Leave Feedback</MenuItem>
                            <MenuItem onClick={handleMenuClose}>View Feedback</MenuItem>
                        </Menu>
                        {/* Settings */}

                        <Tooltip

                            title={content.future_scope_tooltip}
                            arrow
                        >
                            <IconButton size="large" aria-label="settings" sx={{ borderRadius: '4px', backgroundColor: '#F5F5F5' }}
                            /* onClick={handleSettingsMenuOpen} */
                            >
                                <Badge sx={{ color: '#001D5B' }}>
                                    <SettingsOutlinedIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={settingsMenuAnchor}
                            open={Boolean(settingsMenuAnchor)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleMenuClose}>Account Settings</MenuItem>
                            <MenuItem onClick={handleMenuClose}>Privacy Settings</MenuItem>
                        </Menu>

                        {/* Language */}
                        <Tooltip
                            title={content.future_scope_tooltip}
                            arrow
                        >
                            <IconButton
                                size="large"
                                sx={{ borderRadius: '4px', backgroundColor: '#F5F5F5' }}
                                aria-controls={menuId}
                                aria-haspopup="true"
                            /*  onClick={handleLanguageMenuOpen} */

                            >
                                <Badge sx={{ color: '#001D5B' }}>
                                    <LanguageOutlinedIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={languageMenuAnchor}
                            open={Boolean(languageMenuAnchor)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleMenuClose}>English</MenuItem>
                            <MenuItem onClick={handleMenuClose}>German</MenuItem>
                        </Menu>

                        {/* Account Menu */}
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            sx={{ borderRadius: '4px', backgroundColor: '#F5F5F5' }}
                            onClick={handleProfileMenuOpen}
                        >
                            <Badge sx={{ color: '#001D5B', gap: '10px' }}>
                                <AccountCircle />
                                <Typography component="div" sx={{ flexGrow: 1 }}>
                                    {role}
                                </Typography>
                            </Badge>

                        </IconButton>
                        <Menu
                            anchorEl={profileMenuAnchor}
                            open={Boolean(profileMenuAnchor)}
                            onClose={handleMenuClose}

                        >

                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                        {/* to do CLIENT LOGO integration */}
                        <Badge sx={{ paddingLeft: '10px' }}>
                            <img
                                src="/client_logo.png"
                                alt="Client Logo"
                                style={{
                                    padding: '0 12px',
                                    width: '85.83px',
                                    borderRadius: '4px',
                                    objectFit: 'contain',

                                }}
                            />
                        </Badge>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none', lg: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color='#001D5B'
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
        </Box >
    );
}




