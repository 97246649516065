import React from 'react';

import '../styles/main-body.css';

import { Outlet } from 'react-router-dom';

const MainContent = ({ searchQuery }) => {

    return (

        <Outlet searchQuery={searchQuery} />

    );
}

export default MainContent;